import deepmerge from "deepmerge";

let _isLoaded = false;
const _eventQueue = [];

const setLoaded = state => {
	_isLoaded = state;
};

const setBookingSearchBox = (
	{ from, to, isHotel = false, force = false } = {},
	priority = 100,
	trigger = false
) => {
	if (!trigger) {
		const settings = {
			packages: {},
			flights: {},
			cruises: {},
		};

		if (from) {
			settings.packages.selectedFrom = {
				value: from,
				force,
			};
			settings.flights.selectedFrom = {
				value: from,
				force,
			};
			settings.cruises.selectedFrom = {
				value: from,
				force,
			};
		}

		if (to) {
			settings.packages.selectedTo = {
				value: to,
				isHotel,
				force,
			};
		}

		_eventQueue.push({ settings, priority });
	}

	if (!_isLoaded && !trigger) {
		return false;
	}

	const _prioritizedQueue = _eventQueue
		.sort((a, b) => a.priority - b.priority)
		.map(event => event.settings);

	if (_prioritizedQueue.length) {
		const settings = deepmerge.all(_prioritizedQueue);
		window.WEB_CDN_BSB.overrideDefaults(settings);
		_eventQueue.length = 0;
	}

	return true;
};

export { setLoaded, setBookingSearchBox };
