/**
 * Do not edit directly — this is auto generated by style-dictionary
 * Updates are made via ./src/tokens/*.json
 */

export const ColorTl100 = "#024e49";
export const ColorTl200 = "#017f7c";
export const ColorTl300 = "#00aaa6";
export const ColorTl400 = "#cceeed";
export const ColorTl500 = "#e5f2f3";
export const ColorBl100 = "#01172e";
export const ColorBl200 = "#00203e";
export const ColorBl300 = "#003c71";
export const ColorBl400 = "#adc5d9";
export const ColorBl500 = "#e4ebf2";
export const ColorN100 = "#333333";
export const ColorN200 = "#666666";
export const ColorN300 = "#b2b5b6";
export const ColorN400 = "#dbdcdc";
export const ColorN500 = "#f7f7f7";
export const ColorRd100 = "#3f000c";
export const ColorRd200 = "#6a0114";
export const ColorRd300 = "#b00121";
export const ColorRd400 = "#e3a4af";
export const ColorRd500 = "#f9ebed";
export const ColorGr100 = "#1b2d00";
export const ColorGr200 = "#2c4c01";
export const ColorGr300 = "#4a7e01";
export const ColorGr400 = "#bed1a4";
export const ColorGr500 = "#f1f5eb";
export const ColorGs100 = "#111e29";
export const ColorGs200 = "#333f49";
export const ColorGs300 = "#444f59";
export const ColorGs400 = "#a9b2ba";
export const ColorGs500 = "#bac2ca";
export const ColorBlk100 = "#000000";
export const ColorBlk200 = "#010d1a";
export const ColorWh = "#ffffff";
export const ColorPrimaryLight = "#a7e1f4"; // Deprecated
export const ColorPrimaryBase = "#008fbf"; // Deprecated
export const ColorPrimaryDark = "#0f2541"; // Deprecated
export const ColorAccentBase = "#bef564"; // Deprecated
export const ColorGreyLight = "#f2f2f2"; // Deprecated
export const ColorGreyMedium = "#999999"; // Deprecated
export const ColorGreyDark = "#444444"; // Deprecated
export const ColorGreyBase = "#999999"; // Deprecated
export const ColorGreyBackground = "#f2f2f2"; // Deprecated
export const ColorGreyBorder = "#cccccc"; // Deprecated
export const ColorBaseBlueLight = "#a7e1f4"; // Deprecated
export const ColorBaseBlueMedium = "#008fbf"; // Deprecated
export const ColorBaseBlueDark = "#0f2541"; // Deprecated
export const ColorBaseBlueBase = "#008fbf"; // Deprecated
export const ColorSocialFacebook = "#6081c4";
export const ColorSocialX = "#43bdef";
export const ColorSocialPinterest = "#e62b33";
export const ColorSocialYoutube = "#ff0000";
export const ColorSocialInstagram = "#f09433";
export const ColorSystemStarBase = "#ffb612"; // Deprecate?
export const ColorSystemHighlightBase = "#ffd100"; // Hotel price
export const ColorSystemErrorBase = "#b00121";
export const ColorSystemErrorLight = "#fff2f2";
export const ColorSystemSuccessBase = "#046239";
export const ColorSystemSuccessLight = "#e5f2f3";
export const ColorSystemWarningBase = "#fdac10";
export const ColorSystemWarningLight = "#fff8e6";
export const BreakpointMinSm = "36rem";
export const BreakpointMinMd = "48rem";
export const BreakpointMinLg = "62rem";
export const BreakpointMinXl = "75rem";
export const BreakpointMaxSm = "35.9375rem";
export const BreakpointMaxMd = "47.9375rem";
export const BreakpointMaxLg = "61.9375rem";
export const BreakpointMaxXl = "74.9375rem";
export const BorderRadiusBase = "0.25rem";
export const PageWidthBase = "71.25rem";
export const FontFamilyBase = "'Noto Sans', sans-serif";
export const FontFamilySerif = "'Noto Serif', serif";
export const FontFamilyMonospace = "'Fira Code', monospace";
export const FontSizeBase = "0.875rem";
export const FontWeightExtraLight = 200;
export const FontWeightLight = 300;
export const FontWeightNormal = 400;
export const FontWeightSemiBold = 600;
export const FontWeightBase = 400;
export const FontWeightBold = 600;
export const SpacingMicro = "0.5rem";
export const SpacingXs = "1rem";
export const SpacingS = "1.5rem";
export const SpacingM = "2.5rem";
export const SpacingL = "3.5rem";
export const SpacingXl = "5rem";
export const SelectionDarkColor = "#ffffff";
export const SelectionDarkBackgroundColor = "#024e49";
export const SelectionLightColor = "#000000";
export const SelectionLightBackgroundColor = "#cceeed";
export const SelectionColor = "#ffffff";
export const SelectionBackgroundColor = "#024e49";
export const LettingSpacingBase = "normal";
export const LettingSpacingEyeBrow = "0.195rem";
export const HeadingMarginTop = "0";
export const HeadingMarginBottom = "0.5rem";
export const HeadingSizeJumboFontSize = "3.75rem";
export const HeadingSizeJumboLineHeight = "4.5rem";
export const HeadingSizeJumboFontWeight = 200;
export const HeadingSizeExtraLargeFontSize = "2.5rem";
export const HeadingSizeExtraLargeLineHeight = "3rem";
export const HeadingSizeExtraLargeFontWeight = 200;
export const HeadingSizeLargeFontSize = "1.75rem";
export const HeadingSizeLargeLineHeight = "2.125rem";
export const HeadingSizeLargeFontWeight = 300;
export const HeadingSizeMediumFontSize = "1.375rem";
export const HeadingSizeMediumLineHeight = "1.75rem";
export const HeadingSizeMediumFontWeight = 300;
export const HeadingSizeSmallFontSize = "1.125rem";
export const HeadingSizeSmallLineHeight = "1.5rem";
export const HeadingSizeSmallFontWeight = 300;
export const HeadingSizeMacroFontSize = "1rem";
export const HeadingSizeMacroLineHeight = "1.375rem";
export const HeadingSizeMacroFontWeight = 400;
export const BodySizeBaseFontSize = "0.875rem";
export const BodySizeBaseLineHeight = "1.3125rem";
export const BodySizeBaseFontWeight = 400;
export const BodySizeMicroFontSize = "0.75rem";
export const BodySizeMicroLineHeight = "1.125rem";
export const BodySizeMicroFontWeight = 400;
export const BodyFontFamily = "'Noto Sans', sans-serif";
export const BodyFontSize = "0.875rem";
export const BodyFontWeight = 400;
export const BodyFontLineHeight = "1.3";
export const BodyFontLetterSpacing = "normal";
export const BodyFontColor = "#666666";
export const ParagraphMarginBottom = "1rem";
export const LabelMarginBottom = "0.5rem";
