const config = {
	domain: process.env.GATSBY_OKTA_DOMAIN,
	issuer: process.env.GATSBY_OKTA_ISSUER,
	clientId: process.env.GATSBY_OKTA_CLIENT_ID,
	scopes: ["openid", "profile", "email", "brand:vwq"],
	responseType: ["token", "id_token"],
	pkce: true,
	disableHttpsCheck: process.env.GATSBY_OKTA_DISABLE_HTTPS_CHECK,
};

const userAccountRoutingPaths = {
	en: {
		base: "user-account",
		callback: "login/callback",
		login: "login",
		profile: "profile",
		groupquote: "group-quote",
	},
	fr: {
		base: "compte-utilisateur",
		callback: "login/callback",
		login: "se-connecter",
		profile: "profil",
		groupquote: "group-quote",
	},
};

// Check for each IDP variable for disabling via Netlify
const socialLogins = [];

const FACEBOOK_IDP = process.env.GATSBY_OKTA_FACEBOOK_IDP;
if (FACEBOOK_IDP && FACEBOOK_IDP !== "null") {
	socialLogins.push({
		id: FACEBOOK_IDP,
		type: "FACEBOOK",
	});
}

const GOOGLE_IDP = process.env.GATSBY_OKTA_GOOGLE_IDP;
if (GOOGLE_IDP && GOOGLE_IDP !== "null") {
	socialLogins.push({
		id: GOOGLE_IDP,
		type: "GOOGLE",
	});
}

const LINKEDIN_IDP = process.env.GATSBY_OKTA_LINKEDIN_IDP;
if (LINKEDIN_IDP && LINKEDIN_IDP !== "null") {
	socialLogins.push({
		id: LINKEDIN_IDP,
		type: "LINKEDIN",
	});
}

const APPLE_IDP = process.env.GATSBY_OKTA_APPLE_IDP;
if (APPLE_IDP && APPLE_IDP !== "null") {
	socialLogins.push({
		id: APPLE_IDP,
		type: "APPLE",
	});
}

// Fallback images
const productImages = {
	hotel:
		"https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto/WestJet/DIGITAL/VWQ/PROFILE/products/hotel.jpg",
	flight:
		"https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto/WestJet/DIGITAL/VWQ/PROFILE/products/flight.jpg",
	cruise:
		"https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto/WestJet/DIGITAL/VWQ/PROFILE/products/cruise.jpg",
};

const FORM_HOOKS_WITH_ICONS = [
	"authenticator-verification-data",
	"enroll-authenticator",
	"challenge-authenticator",
	"select-authenticator-enroll",
];

module.exports = {
	config,
	userAccountRoutingPaths,
	socialLogins,
	productImages,
	FORM_HOOKS_WITH_ICONS,
};
