const LOCALE_EN = "en-CA";
const LOCALE_FR = "fr-CA";
const RCL_LOCALE_EN = "en-US";
const RCL_LOCALE_FR = "fr";
const URL_LOCALE_EN = "en";
const URL_LOCALE_FR = "fr";
const DEFAULT_GATEWAY_EN = "YUL";
const DEFAULT_GATEWAY_FR = "YUL";
const AD_MODULE_OFFER_TYPE_PACKAGE = "Package";
const AD_MODULE_OFFER_TYPE_RETURN_FLIGHT = "Flight";
const AD_MODULE_OFFER_TYPE_ONE_WAY_FLIGHT = "FlightOneWay";
const AD_MODULE_CARD_HEADER_LENGTH = 50;
const LOCAL_STORAGE_KEY_PREFERRED_GATEWAY = "preferredGateway";
const DAYS_NAMES = {
	en: {
		monday: "Mon",
		tuesday: "Tue",
		wednesday: "Wed",
		thursday: "Thu",
		friday: "Fri",
		saturday: "Sat",
		sunday: "Sun",
	},
	fr: {
		monday: "lun",
		tuesday: "mar",
		wednesday: "mer",
		thursday: "jeu",
		friday: "ven",
		saturday: "sam",
		sunday: "dim",
	},
};
const FUNCTIONS_PATH = "/.netlify/functions";
const TEMPLATES = {
	FLUID_PAGE: "pages",
	HOME_PAGE: "homepage",
	UI_PAGE: "ui",
	ALERTS_PAGE: "alerts",
	CRUISE_RESULTS_PAGE: "cruise-results",
	ERROR_PAGE: "error",
	USER_ACCOUNT_PAGE: "user-account",
};
const VWQ_REDIRECT_MODAL_COOKIE_NAME = "vwq-redirect-modal";

module.exports = {
	LOCALE_EN,
	LOCALE_FR,
	DEFAULT_GATEWAY_EN,
	DEFAULT_GATEWAY_FR,
	AD_MODULE_OFFER_TYPE_PACKAGE,
	AD_MODULE_OFFER_TYPE_RETURN_FLIGHT,
	AD_MODULE_OFFER_TYPE_ONE_WAY_FLIGHT,
	AD_MODULE_CARD_HEADER_LENGTH,
	LOCAL_STORAGE_KEY_PREFERRED_GATEWAY,
	DAYS_NAMES,
	FUNCTIONS_PATH,
	RCL_LOCALE_FR,
	RCL_LOCALE_EN,
	URL_LOCALE_FR,
	URL_LOCALE_EN,
	TEMPLATES,
	VWQ_REDIRECT_MODAL_COOKIE_NAME,
};
