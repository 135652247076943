import { useState, useEffect } from "react";

const StringParam = "string";
const BooleanParam = "boolean";

// TODO: Replace with real implementation
// Temp to replace use-query-params because gatsby-plugin-use-query-params is not supported anymore
const useQueryParam = (key, type = StringParam, defaultValue) => {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		// IE11 Support (URLSearchParams not supported)
		if (typeof window !== "undefined") {
			// Get just the very first key that matches
			const _value =
				window.location.search.match(new RegExp(`[?|&](${key})=([^&]+)`))?.[2] ?? defaultValue;

			switch (type) {
				case BooleanParam:
					setValue(_value?.toString()?.toLowerCase() === "true");
					break;
				case StringParam:
				default:
					setValue(_value);
					break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [value, setValue];
};

export { useQueryParam, StringParam, BooleanParam };
