const viewportBreakpoint = 768;
const offsetTop = 50;
const mobileMenuThreshold = 40;
const marginBottomHide = -100;
const exitIntentCookieName = "_ei";
const emailCookieName = "_ea";

module.exports = {
	viewportBreakpoint,
	offsetTop,
	exitIntentCookieName,
	emailCookieName,
	mobileMenuThreshold,
	marginBottomHide,
};
