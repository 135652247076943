import { useState, useEffect } from "react";
import { throttle, debounce } from "throttle-debounce";
import { BreakpointMinLg } from "../styles/variables";

function useIsMobile(method = "debounce", delayTime = 150, breakpoint = BreakpointMinLg) {
	const [isMobile, setIsMobile] = useState(false);
	const delayMethod = method === "throttle" ? throttle : debounce;
	const mobileBreakPoint = breakpoint ? parseFloat(breakpoint) : breakpoint;
	useEffect(() => {
		const handleSetIsMobile = delayMethod(delayTime, () => {
			if (window.innerWidth < mobileBreakPoint * 16) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		});

		handleSetIsMobile();

		window.addEventListener("resize", handleSetIsMobile);

		return () => {
			window.removeEventListener("resize", handleSetIsMobile);
		};
	}, [delayMethod, delayTime, mobileBreakPoint]);

	return isMobile;
}

export { useIsMobile };
