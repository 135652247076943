import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LOCAL_STORAGE_KEY_PREFERRED_GATEWAY } from "../../const/const";
import gatewaysGroup from "../../components/Header/Gateway/Menu/HeaderGateways.mock.json";
import { setBookingSearchBox } from "../../components/SearchBox/setBookingSearchBox";

const PageContext = createContext({
	alias: "",
	setAlias: () => {},
	aliasLoading: true,
	setAliasLoading: () => {},
	preferredGateway: {},
	setPreferredGateway: () => {},
});

const usePageContext = () => useContext(PageContext);

const PageContextProvider = ({ data, children }) => {
	const [alias, setAlias] = useState();
	const [aliasLoading, setAliasLoading] = useState(true);
	const [preferredGateway, setPreferredGateway] = useState();

	useEffect(() => {
		try {
			const storedGateway = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PREFERRED_GATEWAY));
			if (storedGateway) {
				const foundGateway = gatewaysGroup.find(group =>
					group.gateways.some(gateway => gateway.value === storedGateway.value)
				);

				if (foundGateway) {
					setPreferredGateway(storedGateway);
				} else {
					setPreferredGateway({ label: "Montréal", value: "YUL" });
				}
			} else {
				setPreferredGateway({ label: "Montréal", value: "YUL" });
			}
		} catch (err) {
			console.error(err);
		}
	}, []);

	useEffect(() => {
		if (preferredGateway) {
			localStorage.setItem(LOCAL_STORAGE_KEY_PREFERRED_GATEWAY, JSON.stringify(preferredGateway));
			setBookingSearchBox(
				{
					from: preferredGateway.value,
					force: true,
				},
				10
			);
		}
	}, [preferredGateway]);

	return (
		<PageContext.Provider
			value={{
				...data,
				alias,
				setAlias,
				aliasLoading,
				setAliasLoading,
				preferredGateway,
				setPreferredGateway,
			}}>
			{children}
		</PageContext.Provider>
	);
};

PageContextProvider.propTypes = {
	data: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { PageContextProvider, usePageContext };
