import { useState, useEffect } from "react";
import { throttle } from "throttle-debounce";
import {
	BreakpointMinSm,
	BreakpointMinMd,
	BreakpointMinLg,
	BreakpointMinXl,
	BreakpointMaxSm,
	BreakpointMaxMd,
	BreakpointMaxLg,
	BreakpointMaxXl,
} from "../styles/variables";

function useBreakpoints(delayTime) {
	const [breakpoint, setBreakpoint] = useState({
		isXs: false,
		isSm: false,
		isMd: false,
		isLg: false,
		isXl: false,
	});

	const handleDetectBreakpoint = throttle(delayTime || 150, () => {
		if (window) {
			setBreakpoint({
				isXs: window.matchMedia(`(max-width: ${BreakpointMaxSm})`).matches,
				isSm: window.matchMedia(
					`(min-width: ${BreakpointMinSm}) and (max-width: ${BreakpointMaxMd})`
				).matches,
				isMd: window.matchMedia(
					`(min-width: ${BreakpointMinMd}) and (max-width: ${BreakpointMaxLg})`
				).matches,
				isLg: window.matchMedia(
					`(min-width: ${BreakpointMinLg}) and (max-width: ${BreakpointMaxXl})`
				).matches,
				isXl: window.matchMedia(`(min-width: ${BreakpointMinXl})`).matches,
			});
		}
	});

	useEffect(() => {
		handleDetectBreakpoint();

		window.addEventListener("resize", handleDetectBreakpoint);

		return () => {
			window.removeEventListener("resize", handleDetectBreakpoint);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [delayTime]);

	return breakpoint;
}

function useHeightBreakpoints(delayTime) {
	const [breakpoint, setBreakpoint] = useState({
		isHeightXs: false,
		isHeightSm: false,
		isHeightMd: false,
		isHeightLg: false,
		isHeightXl: false,
	});

	const handleDetectBreakpoint = throttle(delayTime || 150, () => {
		if (typeof window !== "undefined") {
			setBreakpoint({
				isHeightXs: window.matchMedia(`(max-height: ${BreakpointMaxSm})`).matches,
				isHeightSm: window.matchMedia(
					`(min-height: ${BreakpointMinSm}) and (max-height: ${BreakpointMaxMd})`
				).matches,
				isHeightMd: window.matchMedia(
					`(min-height: ${BreakpointMinMd}) and (max-height: ${BreakpointMaxLg})`
				).matches,
				isHeightLg: window.matchMedia(
					`(min-height: ${BreakpointMinLg}) and (max-height: ${BreakpointMaxXl})`
				).matches,
				isHeightXl: window.matchMedia(`(min-height: ${BreakpointMinXl})`).matches,
			});
		}
	});

	useEffect(() => {
		handleDetectBreakpoint();

		window.addEventListener("resize", handleDetectBreakpoint);

		return () => {
			window.removeEventListener("resize", handleDetectBreakpoint);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [delayTime]);

	return breakpoint;
}

export { useBreakpoints, useHeightBreakpoints };
