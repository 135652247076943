/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from "react";
import * as dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import { AlertsProvider, PageContextProvider } from "./src/context";
import { RCLProvider } from "./src/components/RCL";
import { SecurityProvider } from "./src/templates/user-account/context/SecurityProvider";
import {
	exitIntentCookieName,
	emailCookieName,
} from "./src/components/EmailAcquisitionForm/constants";
import "./src/styles/index.scss";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
	const lang = props?.pageContext?.locale?.substring(0, 2)?.toLowerCase() || "en";
	dayjs.locale(lang === "fr" ? "fr" : "en");

	// Reset any Email Acquisition / Exit Intent tracking
	if (window?.sessionStorage) {
		sessionStorage.removeItem(exitIntentCookieName);
		sessionStorage.removeItem(emailCookieName);
	}

	return (
		<SecurityProvider lang={props?.pageContext?.locale}>
			<PageContextProvider data={{ ...props?.pageContext, lang }}>
				<AlertsProvider
					lang={props?.pageContext?.locale}
					staticAlertsCount={props?.pageContext?.numberOfAlerts}
					timestamp={props?.pageContext?.timestamp}>
					<RCLProvider lang={props?.pageContext?.locale}>{element}</RCLProvider>
				</AlertsProvider>
			</PageContextProvider>
		</SecurityProvider>
	);
};
