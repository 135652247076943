import React from "react";
import { getURLQueries } from "../getURLQueries";

const validateReturnURL = (_url, addQueries = false, addHash = false) => {
	try {
		let queryString = "";
		let hash = "";
		let encoded = false; // Check if MBP internal links

		// Handle Base64 URLs
		let cleanedDestination = typeof _url !== "undefined" ? _url : "";

		if (cleanedDestination.length > 4 && cleanedDestination.substring(0, 4) !== "http") {
			try {
				cleanedDestination = atob(decodeURIComponent(cleanedDestination));
				encoded = true;
			} catch (error) {
				cleanedDestination = "";
			}

			// Check if it is valid URL, otherwise revert back
			if (cleanedDestination.substring(0, 4) !== "http") {
				cleanedDestination = "";
			}
		}

		if (cleanedDestination.length > 4 && addQueries && !encoded) {
			const queryList = getURLQueries().queries;
			const keys = Object.keys(queryList);
			let firstKey = true;

			keys.forEach(key => {
				if (key !== "ReturnUrl") {
					queryString += `${firstKey ? "?" : "&"}${key}=${queryList[key]}`;
					firstKey = false;
				}
			});
		}

		if (cleanedDestination.length > 4 && addHash) {
			hash = getURLQueries().hash;
		}

		return cleanedDestination.replace(window.location.origin, "").concat(queryString).concat(hash);
	} catch {
		console.error("Unable to validate return url", _url);
		return null;
	}
};

const processPrograms = linksCopy => {
	if (typeof linksCopy !== "string") {
		return null;
	}
	const textLinks = linksCopy.trim().replace(/\n/g, "").split(",");
	const linksArray = [];

	textLinks.forEach(textLink => {
		const textArray = textLink.trim().split(";");

		const linkObject = {
			url: "",
			code: null,
		};

		let linkFlag = false;

		for (let i = 0; i < textArray.length; i++) {
			// Sanitize array
			const tempString = textArray[i].trim();

			if (tempString.length) {
				textArray[i] = tempString;
			} else {
				textArray[i] = null;
			}

			// Build output array
			if (textArray[i]) {
				// eslint-disable-next-line no-loop-func
				Object.keys(linkObject).forEach(key => {
					const textItem = textArray[i].split(":");

					if (key === textItem[0]) {
						switch (key) {
							case "url":
								// eslint-disable-next-line no-case-declarations
								const href = textArray[i].substr(key.length + 1, textArray[i].length - 1);
								linkObject[key] = href;
								linkFlag = true;
								break;
							case "code":
								// eslint-disable-next-line prefer-destructuring
								linkObject[key] = textItem[1];
								linkFlag = true;
								break;
							default:
								linkFlag = false;
						}
					}
				});
			}
		}

		// Update output array
		if (linkFlag && linkObject.url) {
			linksArray.push(linkObject);
		}
	});

	if (linksArray) {
		return linksArray;
	}
	return null;
};

const getAccountCreatedStatus = () => {
	const oktaAction = getURLQueries("type_hint");
	const oktaSession = getURLQueries("session_hint");

	return oktaAction === "ACTIVATION" && oktaSession === "AUTHENTICATED";
};

const replaceWithJSX = (str, find, replace) => {
	// Recursively iterate through JSX objects for placeholder text replace with components
	const replaceString = (_str, _find, _replace) => {
		try {
			// Attempt to replace delimiter with replacement Component
			const fragments = _str.split(_find);
			const result = [];

			for (let i = 0; i < fragments.length; i++) {
				result.push(<span key={i}>{fragments[i]}</span>);
				if (i < fragments.length - 1) result.push(<span key={`${i}-replace`}>{_replace}</span>);
			}

			return result;
		} catch (error) {
			// If failure, return original str value
			return str;
		}
	};

	try {
		const recursiveMap = children =>
			React.Children.map(children, child => {
				if (!React.isValidElement(child)) {
					return replaceString(child, find, replace);
				}

				if (child.props.children) {
					// eslint-disable-next-line no-param-reassign
					child = React.cloneElement(child, {
						children: recursiveMap(child.props.children),
					});
				}

				return child;
			});

		return recursiveMap(str);
	} catch (error) {
		// If failure, return original str value
		return str;
	}
};

const removeDiacritics = source => {
	let filteredString = source;

	if (typeof String.prototype.normalize === "function") {
		filteredString = filteredString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	} else {
		const diacriticDictionary = {
			// Allowed diacritical marked letters
			â: "a",
			à: "a",
			á: "a",
			ç: "c",
			é: "e",
			ê: "e",
			è: "e",
			ë: "e",
			î: "i",
			ï: "i",
			ô: "o",
			û: "u",
			ù: "u",
			ü: "u",
		};

		// Convert all invalid characters to dictionary safe characters
		filteredString = source
			.toLowerCase()
			.replace(/[^\w]/g, character => diacriticDictionary[character] || character);
	}

	return filteredString;
};

const removeHypens = source => source.replace(/-|–|—/g, " ");

const cleanString = source => {
	let filteredString = removeHypens(source);
	filteredString = removeDiacritics(filteredString);
	return filteredString;
};

const allowNumbers = (event, length, max = 10) => {
	// Limits the keyboard entry field (restricts to numbers)
	const charCode = event.keyCode.which ? event.keyCode.which : event.keyCode;
	if ((charCode > 31 && (charCode < 48 || charCode > 57)) || length > max - 1) {
		event.preventDefault();
	}
};

const formatDateString = date => {
	// Handle date conversions and formatting
	const year = date.getFullYear();
	const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
	const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

	return `${year}-${month}-${day}`;
};

const profileMapping = (profileDataLoaded, changedProfileData, profileInitStateProp) => {
	const phoneData = {
		phones: [
			{
				type: "PERSONAL",
				phoneNumber: changedProfileData.mobilePhone,
			},
		],
	};

	const departureData = [
		{
			preferenceType: "PRIMARY",
			code: changedProfileData.preferredGateway,
			name: "",
		},
	];

	const languageData = {
		contactPreferences: {
			languageCode: changedProfileData.preferredLanguage.toUpperCase(),
		},
	};

	// Required fields
	const profileDataUpdated = {
		firstName: changedProfileData.firstName,
		lastName: changedProfileData.lastName,
	};

	// Merge changed object data
	let updatedProfileData = {
		...profileDataLoaded,
		...profileDataUpdated,
	};

	if (changedProfileData.preferredLanguage !== profileInitStateProp.preferredLanguage) {
		if (updatedProfileData?.contactPreferences?.languageCode) {
			updatedProfileData.contactPreferences.languageCode =
				changedProfileData.preferredLanguage.toUpperCase();
		} else {
			updatedProfileData = {
				...updatedProfileData,
				...languageData,
			};
		}
	}

	// Update birthDate if added
	if (
		changedProfileData?.birthDate !== profileInitStateProp?.birthDate &&
		changedProfileData?.birthDate !== ""
	) {
		updatedProfileData = {
			...updatedProfileData,
			birthDate: `${changedProfileData.birthDate}T00:00:00`,
		};
	} else if (changedProfileData?.birthDate === "") {
		delete updatedProfileData.birthDate;
	}

	// Add or update mobile phone array data
	if (changedProfileData.mobilePhone !== profileInitStateProp.mobilePhone) {
		const personalPhone = updatedProfileData?.phones?.filter(phone => phone.type === "PERSONAL");

		if (personalPhone?.length > 0) {
			personalPhone[0].phoneNumber = changedProfileData.mobilePhone;
		} else {
			updatedProfileData = { ...updatedProfileData, ...phoneData };
		}
	}

	// Add or update departure gateway array data
	if (changedProfileData.preferredGateway !== profileInitStateProp.preferredGateway) {
		const primaryGateway = updatedProfileData?.contactPreferences?.departureCities?.filter(
			departure => departure.preferenceType === "PRIMARY"
		);

		if (primaryGateway?.length > 0) {
			primaryGateway[0].code = changedProfileData.preferredGateway;
		} else {
			const contactPreferences = {
				...updatedProfileData,
			};

			contactPreferences.contactPreferences.departureCities = departureData;
			updatedProfileData = { ...updatedProfileData, ...contactPreferences };
		}
	}

	// Handle context update
	const contextData = {
		context: {
			campaign: {
				code: "consumer-account-profile",
				source: process.env.GATSBY_APP_ID,
				channel: "ONLINE",
				type: "SUBSCRIPTION",
			},
			page: {
				url: window.location.href,
				title: document.title,
			},
		},
	};

	updatedProfileData = { ...updatedProfileData, ...contextData };

	return updatedProfileData;
};

const setLoginType = (oktaToken, socialLogins) => {
	const postBody = {
		email: oktaToken.idToken.claims.email,
		contactType: "customer",
	};

	// Determine the social login type, if applicable
	socialLogins.some(login => {
		if (login.id === oktaToken.idToken.claims.idp) {
			postBody.additionalProperties = [
				{
					code: "LastLoginType",
					value: login.type,
				},
			];
			return true;
		}

		return false;
	});

	// Fallback to Okta login type
	if (postBody.additionalProperties === undefined) {
		postBody.additionalProperties = [
			{
				code: "LastLoginType",
				value: "OKTA",
			},
		];
	}

	fetch(`${process.env.GATSBY_OKTA_AUTH_API_URL}/loginType/current`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${oktaToken.accessToken.accessToken}`,
		},
		method: "PATCH",
		body: JSON.stringify(postBody),
	})
		.then(res => res)
		.catch(error => {
			console.error("setLoginType:: error: ", error);
		});
};

const processProperties = (data, propertyObject) => {
	if (typeof data !== "string") {
		return null;
	}

	const mappingData = data.trim().replace(/\n/g, "").split(",");
	const output = [];

	mappingData.forEach(mapping => {
		const propArray = mapping.trim().split(";");

		const properties = { ...propertyObject };

		let propFlag = false;
		let propCounter = 0;

		for (let i = 0; i < propArray.length; i++) {
			// Sanitize array
			const tempString = propArray[i].trim();

			if (tempString.length) {
				propArray[i] = tempString;
			} else {
				propArray[i] = null;
			}

			// Build output array
			if (propArray[i]) {
				// eslint-disable-next-line no-loop-func
				Object.keys(properties).forEach(key => {
					const keyValuePair = propArray[i].split(":");

					if (key === keyValuePair[0]) {
						switch (key) {
							case "text":
							case "target":
							case "code":
							case "name":
								// eslint-disable-next-line prefer-destructuring
								properties[key] = keyValuePair[1];
								propFlag = true;
								propCounter += 1;
								break;
							case "href":
								// eslint-disable-next-line no-case-declarations
								const href = propArray[i].substr(key.length + 1, propArray[i].length - 1);
								properties[key] = href;
								propFlag = true;
								propCounter += 1;
								break;
							default:
								propFlag = false;
						}
					}
				});
			}
		}

		// Update output array
		if (propFlag && propCounter === Object.keys(properties).length) {
			output.push(properties);
			propCounter = 0;
		}
	});

	if (output.length) {
		return output;
	}
	return null;
};

const getMandatoryStatusQMA = data => {
	// Determine if user is missing QMA mandatory mobile phone property data
	const primaryGateway = data?.contactPreferences?.departureCities?.filter(
		departure => departure.preferenceType === "PRIMARY"
	);

	const personalPhone = data?.phones?.filter(phone => phone.type === "PERSONAL");

	return (
		Boolean(data.firstName) === false ||
		Boolean(data.lastName) === false ||
		Boolean(data.mobilePhone) === false ||
		Boolean(primaryGateway?.length > 0 ? primaryGateway[0].code : undefined) === false ||
		Boolean(personalPhone?.length > 0 ? personalPhone[0].phoneNumber : undefined) === false
	);
};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export {
	validateReturnURL,
	processPrograms,
	getAccountCreatedStatus,
	replaceWithJSX,
	cleanString,
	allowNumbers,
	formatDateString,
	profileMapping,
	setLoginType,
	processProperties,
	getMandatoryStatusQMA,
	delay,
};
