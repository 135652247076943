import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { config, userAccountRoutingPaths } from "../config";
import { UserProvider } from "../../../context/User";

const SecurityProvider = ({ children, lang }) => {
	const [oktaAuth, setOktaAuth] = useState();
	const locale = lang.substring(0, 2);

	const restoreOriginalUri = async () => {
		const routing = userAccountRoutingPaths[locale];
		const redirectAfterLoginUrl = `/${locale}/${routing.base}/${routing.profile}`;

		navigate(redirectAfterLoginUrl);
	};

	useEffect(() => {
		const oktaAuthInstance = new OktaAuth({
			...config,
			redirectUri: `${window.location.origin}/${locale}/login/callback`,
		});

		setOktaAuth(oktaAuthInstance);
	}, [locale]);

	if (!oktaAuth) return children;

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<UserProvider lang={locale}>{children}</UserProvider>
		</Security>
	);
};

SecurityProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	lang: PropTypes.string,
};

SecurityProvider.defaultProps = {
	lang: "en",
};

export default SecurityProvider;
export { SecurityProvider };
